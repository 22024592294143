<template>



    <el-row type="flex">

      <el-tooltip
        v-if="user.roles && user.roles.length"
        content="Скачать инструкцию"
        placement="top"
        :open-delay="500"
      >
        <el-button
          type="text"
          icon="fas fa-file-alt"
          @click="downloadReadme"
        ></el-button>
      </el-tooltip>



      <notifications
        v-if="user.roles && user.roles.length"
        style="margin-left: 15px"
        :user-id="user.id"
      ></notifications>


      <div style="margin-right: 10px; padding-top:9px">
        <div>{{ user.last_name }} {{ user.first_name }}</div>
      </div>



      <el-tooltip content="Выход" placement="top-start">
        <el-button
          size="mini"
          type="text"
          icon="fas fa-sign-out-alt"
          @click="logout"
        >
        </el-button>
      </el-tooltip>


    </el-row>

</template>

<script>
import {mapGetters} from "vuex";
import Notifications from "@/components/Notifications.vue";
import router from "@/router";

export default {
  name: "actions-component",
  components: {Notifications},

  props: {},

  watch: {},
  computed: {
    ...mapGetters(['user']),
    actionsTemplate() {
      return this.$companyConfiguration.settings.getActionsTemplate();
    },
  },
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {
    activeUserSelected(selection) {
      localStorage.userId = selection[0].id;
      this.$router.go();
    },

    logout() {
      localStorage.removeItem('jwt');
      router.push({ name: 'Login'});
    },

    downloadReadme(){
      let fileLink = document.createElement('a');
      fileLink.href = '/readme.docx';
      fileLink.setAttribute('download', 'Инструкция');
      document.body.appendChild(fileLink);
      fileLink.click();
    }
  }
}
</script>


<style lang="scss">

</style>